import { getLocale } from 'next-intl/server';

import { auth } from '@shared/misc';

import { getLocalPaths, getMembershipInformation } from '../../utils';
import { getProfilePicture } from '../../utils/data/get-profile-picture';
import { getCardsSectionData } from '../../utils/helpers/get-cards-section-data';
import { getProfileDisplayName } from '../../utils/helpers/get-profile-display-name';
import { getLanguagesContent } from '../../utils/hooks/get-languages-content';

import { getHeaderContent, getHeaderProfileDropdownContent, getNavigationData } from './graphql';
import { HeaderClient } from './header-client';
import { getNavigationLinksByPageType } from './utils';

export const Header = async () => {
  const session = await auth();
  const isAuthenticated = session !== null;
  const personId = session?.personId;
  const { applicationDefinitionData, customerProfileData, membershipApplicationData } = personId
    ? await getCardsSectionData(personId)
    : {
        applicationDefinitionData: null,
        customerProfileData: null,
        membershipApplicationData: null,
      };
  const profileDisplayName = getProfileDisplayName(customerProfileData);
  const pictureName = customerProfileData?.profilePicture?.originalSizePictureUri;
  const profilePicture = pictureName ? await getProfilePicture({ personId, pictureName }) : null;
  const localeURLSegment = await getLocale();
  const languagesCmsContent = await getLanguagesContent();
  const cmsContent = await getHeaderContent();
  const headerNavigationData = await getNavigationData();
  const navigationItems = await getNavigationLinksByPageType(headerNavigationData);
  const profileDropdownCmsContent = await getHeaderProfileDropdownContent();
  const paths = await getLocalPaths();

  let statusChipLabel;

  if (customerProfileData) {
    const { isNewUser, isPreviousButNotCurrentMember } = getMembershipInformation({
      applicationDefinition: applicationDefinitionData,
      customerProfile: customerProfileData,
      membershipApplication: membershipApplicationData,
    });

    if (isNewUser) {
      statusChipLabel = cmsContent.becomeMemberLabel;
    }

    if (isPreviousButNotCurrentMember) {
      statusChipLabel = cmsContent.renewNowLabel;
    }
  }

  const profileSummary = {
    displayName: profileDisplayName,
    mdrtId: customerProfileData?.mdrtId || null,
    photoUrl: profilePicture,
  };

  const headerContentProps = {
    isAuthenticated,
    languagesCmsContent,
    localeURLSegment,
    profileDropdownCmsContent,
    profileSummary,
    statusChipLabel,
    statusChipUrl: cmsContent.membershipPageLink,
  };

  return (
    <HeaderClient
      headerContentProps={headerContentProps}
      navigationItems={navigationItems}
      paths={paths}
      showHeader={!customerProfileData && Boolean(session)}
    />
  );
};
